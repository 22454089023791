import React, {useState, useEffect, useContext} from 'react';
import styles from './InfoMessage.module.css';
import {AppContext} from "../../redux/AppContext"; // import your css file
import { setInfoMessage } from '../../redux/actions';

function InfoMessage() {
    const {state, dispatch} = useContext(AppContext);
    // const { setMessage } = useInfoMessage();

    useEffect(() => {
        if (state.infoMessage) {
            console.log( state.infoMessage);
            const timer = setTimeout(() => {
                dispatch( setInfoMessage( null));
            }, 2000); // 5 seconds
            return () => clearTimeout(timer);
        }
    }, [state.infoMessage]);

    return state.infoMessage ? (
        <div className={styles["info-message"]}>
            {typeof(state.infoMessage) == "string"?state.infoMessage:JSON.stringify(state.infoMessage)}
        </div>
    ) : null;
}

export default InfoMessage;
