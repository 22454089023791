import React, {useContext, useEffect, useState} from "react";
import styles from "./Settings.module.css"
import { getApi } from '../../services/apiService';
import {AppContext} from "../../redux/AppContext";
import {useAccount, useMsal} from "@azure/msal-react";
import {ThemeContext} from "../../redux/ThemeContext";
import Ranking from "../Ranking/Ranking";
import {useNavigate} from "react-router-dom";
import {setDisableFromat} from "../../redux/actions";
// import {setBalance} from "../../redux/actions";

function Settings() {
    const { state, dispatch } = useContext(AppContext);
    const { accounts, instance} = useMsal();
    const { isDisableFormat } = state;
    const account = useAccount(accounts[0] || {});
    const { theme } = useContext(ThemeContext);
    const navigate = useNavigate();
    // const { instance } = useMsal();
    const [balance, setBalance] = useState(null);

    useEffect(() => {
        const loadBalance = async () => {
            const result = await getApi(dispatch, instance).get("/api/account/balance");
            setBalance(result?.data?.balance)
        }
        loadBalance()
    },[dispatch, instance])

    return (
        <div className={styles['container']}>
            <div className={"title-with-back"}>
                <a onClick={() => navigate(-1)}>
                    <img src={theme == "dark" ? "/icons8-previous-dark-50.png" : "/icons8-previous-light-50.png"}
                         alt="back"/>
                </a>
                <h3>{account?.name}</h3>
            </div>
            <div className={styles['profile-container']}>
                {balance && <div className={`list-item ${styles["balance-div"]}`}>Your current month usage is <span
                    className={styles["balance"]}>${Math.trunc(balance * 100) / 100}</span></div>}
            </div>
        </div>
    )
}


export default Settings;
