import styles from "./ManagerTop.module.css"
import React, {useContext} from "react";
import {Link, Route, Routes, useNavigate} from "react-router-dom";
import {ThemeContext} from "../../redux/ThemeContext";
import ManagerFeedback from "../ManagerFeedback/ManagerFeedback";


// Users.js
const Users = () => {
    return <div>Users Page</div>;
};


const Feedback = () => {
    return <div>Feedback Page</div>;
};


function ManagerTop() {
    const { theme } = useContext(ThemeContext);
    const navigate = useNavigate();
    return (
        <div className={styles['container']}>
            {/*<div className={"title-with-back"}>*/}
                {/*<a onClick={() => navigate(-1)}>*/}
                {/*    <img src={theme == "dark" ? "/icons8-previous-dark-50.png" : "/icons8-previous-light-50.png"}*/}
                {/*         alt="back"/>*/}
                {/*</a>*/}
                {/*<h3>Manager</h3>*/}
            {/*</div>*/}
            <div className={styles['manager-container']}>
                <div className={styles['manager-container-left']}>
                    <div><Link to="feedback">Feedback</Link></div>
                    {/*<div><Link to="users">Users</Link></div>*/}
                </div>
                <div className={styles['manager-container-right']}>
                    <Routes>
                        <Route path="feedback" element={<ManagerFeedback/>}/>
                        {/*<Route path="users" element={<Users/>}/>*/}
                        {/* Add more sub-routes here as needed */}
                    </Routes>
                </div>
            </div>
        </div>
    )
}


export default ManagerTop;
