import React, {useContext, useEffect, useRef, useState} from "react";
import {AppContext} from "../../redux/AppContext";
import styles from './TopMostNavBar.module.css'
import {useMsal} from "@azure/msal-react";
import {ThemeContext} from "../../redux/ThemeContext";
import {useNavigate} from "react-router-dom";
import {setChatLayout, setCurrentUsage, setDisableFromat, setInfoMessage} from "../../redux/actions";
import {getApi} from "../../services/apiService";
import ContextModal from "../ContextModal/ContextModal";
import MaxModal from "../MaxModal/MaxModal";
import Busy from "../Busy/Busy";
import AssistantWrapper from "../AssistantWrapper/AssistantWrapper";



function TopMostNavBar() {
    const { instance, accounts, inProgress } = useMsal();
    const { theme, switchTheme } = useContext(ThemeContext);
    const navigate = useNavigate();
    const { state, dispatch } = useContext(AppContext);
    const { chatLayout, currentUsage, profile, isDisableFormat } = state;
    // const fileInputRef = useRef(null);


    useEffect(()=> {
        const loadMetric = () => {
            getApi(dispatch, instance, accounts).get('/api/metrics/monthly_usage/current')
                .then(response => {
                    dispatch(setCurrentUsage(response.data));
                })
                .catch((error)=> { /*error handled in apiService*/});
        }
        loadMetric()
    }, [])

    const rotateLayout = () => {
        dispatch(setChatLayout(chatLayout==="row"?"column":"row"));
    }

    const [showHelpArgs, setShowHelpArgs] = useState(null);
    const [showMoreArgs, setShowMoreArgs] = useState(null);
    // const [showSuggestionModal, setShowSuggestionModal] = useState(false);
    const [loading, setLoading] = useState(false);

    // const triggerFileInput = () => {
    //     // Trigger the file input click event
    //     fileInputRef.current.click();
    // };

    // const handleFileChange = async (event) => {
    //     const file = event.target.files[0];
    //     if (!file) return;
    //
    //     await handleDropFiles(event.target.files);
    // };
    //
    // const handleDropFiles = async (dropFiles) => {
    //     if (dropFiles.length<= 0) return;
    //     try {
    //         setLoading(true);
    //         for (let i = 0; i < dropFiles.length; i++) {
    //             // console.log(dropFiles[i]);
    //             await handleFileInput(dropFiles[i]);
    //         }
    //     } catch (error) {
    //         console.error('Error uploading file:', error);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    // const handleFileInput = async (file) => {
    //     console.log( file);
    //     const formData = new FormData();
    //     formData.append('file', file);
    //     const response = await getApi(dispatch, instance).post('/api/upload', formData, {
    //         headers: {
    //             'Content-Type': 'multipart/form-data',
    //         },
    //     });
    //     setAttachmentFileUrl((current) => [...current, {
    //         url: response.data.file_url,
    //         name: file.name,
    //         is_image: file.type.startsWith( 'image')
    //     }])
    // }

    // const handleSubmitSuggestion = async() => {
    //     try {
    //         setLoading(true);
    //         const response = await getApi(dispatch, instance).post('/api/feedback', {
    //             ...suggestionParams,
    //             attachment_files: attachmentFileUrl.map((d)=> d.url)
    //         });
    //         dispatch( setInfoMessage('Thank you. Your feedback has been received!'));
    //         setShowSuggestionModal(false);
    //         setAttachmentFileUrl([]);
    //         setSuggestionParams({});
    //     } finally {{
    //         setLoading(false);
    //     }}
    // }

    // const [attachmentFileUrl, setAttachmentFileUrl] = useState([]);
    // const [suggestionParams, setSuggestionParams] = useState({})

    // const suggestionModal = showSuggestionModal && (
    //     <MaxModal
    //         show={true}
    //         handleClose={()=> setShowSuggestionModal(false)}>
    //
    //         <div className={styles["suggestion-modal-container"]}>
    //             <h1>Share your feedback</h1>
    //             <div className={styles["suggestion-modal-row"]}>
    //                 <div className={styles["suggestion-modal-title"]}>Category</div>
    //                 <div className={`${styles["suggestion-modal-input"]} h-w-100`}
    //                 ><select
    //                     value={suggestionParams.category}
    //                     onChange={(e) => {
    //                         setSuggestionParams(prev => ({...prev, category: e.target.value}))
    //                     }}
    //                 >
    //                     <option value="">Select category</option>
    //                     <option value={"bug"}>bug</option>
    //                     <option value={"suggestion"}>suggestion</option>
    //                     <option value={"question"}>question</option>
    //                     <option value={"other"}>other</option>
    //                 </select></div>
    //             </div>
    //             <div className={`${styles["suggestion-modal-row"]} ${styles["suggestion-modal-row-main"]}`}>
    //                 <div className={styles["suggestion-modal-title"]}>Your feedback</div>
    //                 <div className={`${styles["suggestion-modal-input"]}`}>
    //                     <AssistantWrapper
    //                         notifyImprovedText={(value) => setSuggestionParams(prev=>({...prev, text: value}))}
    //                         getCurrentText={()=> { return suggestionParams.text}}
    //                     />
    //                     <textarea className={"code"}
    //                         value = {suggestionParams.text}
    //                         onChange = {e=> setSuggestionParams(prev=>({...prev, text: e.target.value}))}
    //                     ></textarea>
    //                 </div>
    //             </div>
    //             <div className={styles["suggestion-modal-row"]}>
    //                 <div className={styles["suggestion-modal-title"]}>
    //                     <div>Attachment</div>
    //                     <div className={styles["icon"]}
    //                          onClick={triggerFileInput}
    //                     >
    //                         <img
    //                             src={theme == "dark" ? "/icons8-attach-50-dark.png" : "/icons8-attach-50-light.png"}
    //                         />
    //                     </div>
    //
    //                 </div>
    //                 <div className={`${styles["suggestion-modal-input"]} ${styles["suggestion-modal-attachment"]}`}>
    //                     {attachmentFileUrl.map((file, idx) => (
    //                             <div className={styles["attachment-container"]}>
    //                                 {file.is_image && <img className={styles["image-in-thread"]}
    //                                                        src={file.url}/>}
    //                                 {!file.is_image && <div>{file.name}</div>}
    //                                 <div onClick={() => setAttachmentFileUrl((current)=>
    //                                     current.filter((_, index)=> index!==idx)
    //                                 )}
    //                                      className="icon-button-smaller-x delete" title={"delete chat entry"}>
    //                                     <img
    //                                         src={theme == "dark" ? "/icons8-delete-30-dark.png" : "/icons8-delete-30-light.png"}/>
    //                                 </div>
    //                             </div>
    //                     ))}
    //                 </div>
    //             </div>
    //             <hr/>
    //             <div className={styles["suggestion-modal-panel"]}>
    //                 <button type={"submit"} className={"button"}
    //                         onClick={() => handleSubmitSuggestion()}
    //                         disabled={!(suggestionParams.category && suggestionParams.text)}
    //                 >
    //                     Send
    //                 </button>
    //             </div>
    //             <input
    //                 type="file"
    //                 // accept="application/pdf,text/plain"
    //                 style={{display: 'none'}}
    //                 ref={fileInputRef}
    //                 onChange={handleFileChange}
    //             />
    //         </div>
    //     </MaxModal>
    // )

    const helpModal = !!showHelpArgs && (
        <ContextModal
            showClose={false}
            clickPosition={showHelpArgs.pos}
            handleClose={() => setShowHelpArgs(null)}>
            <div className={styles["help-menu-container"]}>
                <div className={"selection-list-item"}
                     onClick={() => {
                         navigate('/feedback')
                         setShowHelpArgs(null)
                     }}>
                    <div>Feedback</div>
                </div>
                <div className={"selection-list-item"}
                    onClick = {()=> {
                        window.open('/updates.html', '_blank');
                        setShowHelpArgs(null)
                    }}
                >
                    <div>Updates</div>
                </div>
            </div>

        </ContextModal>
    )

    const moreModal = !!showMoreArgs && (
        <ContextModal
            // showClose={false}
            nonblocking={true}
            clickPosition={showMoreArgs.pos}
            handleClose={() => setShowMoreArgs(null)}>
            <div className={styles["more-menu-container"]}>
                <div className={"selection-list-item"}
                     onClick={() => {
                         navigate('/settings')
                         setShowMoreArgs(null)
                     }}>
                    <div>Settings</div>
                </div>
                <div className={`selection-list-item ${styles["row-panel"]}`}
                     onClick={()=> {
                         dispatch(setDisableFromat(!isDisableFormat))
                     }}
                >
                    <input type="checkbox"
                           checked={isDisableFormat}
                           onChange={(e)=> {
                               dispatch(setDisableFromat(e.target.checked))
                           }}
                    />Disable markdown formating
                </div>
            </div>

        </ContextModal>
    )


    return (
        <div className={styles["container"]}>
            <div className={styles["top"]}>
                {profile?.permissions.includes('manager') &&
                <div className={styles["icon"]}
                     onClick={() => {
                         navigate('/manager')
                     }}>
                    <img src={theme == "dark" ? "/icons8-accounting-50--dark.png" : "/icons8-accounting-50--light.png"}
                         title="Manager"/>
                </div>}
                <div className={styles["icon"]}
                     onClick={() => {
                         navigate('/chat')
                     }}>
                    <img src={theme == "dark" ? "/icons8-chat-50--dark.png" : "/icons8-chat-50--light.png"}
                         title="Chat"/>
                </div>
                <div className={styles["icon"]}
                     onClick={() => {
                         navigate('/system_message')
                     }}>
                    <img src={theme == "dark" ? "/icons8-system-50--dark.png" : "/icons8-system-50--light.png"}
                         title="System message"/>
                </div>
                <div className={styles["icon"]}
                     onClick={() => {
                         navigate('/context_artifact')
                     }}>
                    <img src={theme == "dark" ? "/icons8-document-50-dark.png" : "/icons8-document-50-light.png"}
                         title="Predefined context" alt={"Chat"}/>
                </div>
                {/*<div className={styles["icon"]}>*/}
                {/*    <div className={styles["placeholder"]}></div>*/}
                {/*</div>*/}
                {/*<div className={styles["icon"]}>*/}
                {/*    <div className={styles["placeholder"]}></div>*/}
                {/*</div>*/}
                {/*<div className={styles["icon"]}>*/}
                {/*    <div className={styles["placeholder"]}></div>*/}
                {/*</div>*/}
                {/*<div className={styles["icon"]}>*/}
                {/*    <div className={styles["placeholder"]}></div>*/}
                {/*</div>*/}
            </div>
            <div className={styles["bottom"]}>
                {/*<div className={styles["icon"]}>*/}
                {/*    <div className={styles["placeholder"]}></div>*/}
                {/*</div>*/}
                {/*<div className={styles["icon"]}>*/}
                {/*    <div className={styles["placeholder"]}></div>*/}
                {/*</div>*/}
                <div className={styles["icon"]}
                     onClick={switchTheme}>
                    <img
                        src={theme == "dark" ? "/icons8-light-mode-78.png" : "/icons8-dark-mode-50.png"}
                        title="dark/light mode"/>
                </div>
                <div className={styles["icon"]}
                     onClick={rotateLayout}>
                    <img
                        src={theme == "dark" ? (chatLayout === "column" ? "/icons8-column-pane-50--dark.png" : "icons8-row-pane-50--dark.png")
                            : (chatLayout === "column" ? "/icons8-column-pane-50--light.png" : "icons8-row-pane-50--light.png")}
                        title="rotate layout"/>
                </div>
                <div className={styles["icon"]}
                     onClick={() => {
                         navigate('/workspace')
                     }}>
                    <img
                        src={theme == "dark" ? "/icons8-organization-50--dark.png" : "/icons8-organization-50--light.png"}
                        title="worskspace"/>
                </div>
                <div className={`${styles["icon"]} ${styles["current-usage-count-container"]}`}
                     onClick={() => {
                         navigate('/metrics')
                     }}>
                    <img src={theme == "dark" ? "/icons8-ranking-50--dark.png" : "/icons8-ranking-50--light.png"}
                         title="metrics"/>
                    <div className={styles["current-usage-count"]}>{currentUsage}</div>
                </div>
                <div className={styles["icon"]}
                     onClick={(event) => {
                         const rect = event.target.getBoundingClientRect();
                         setShowMoreArgs({pos: {top: rect.bottom, left: rect.right}});
                     }}>
                    <img src={theme == "dark" ? "/icons8-ellipsis-50--dark.png" : "/icons8-ellipsis-50--light.png"}
                         title="more"/>
                </div>
                <div className={styles["icon"]}
                     onClick={(event) => {
                         const rect = event.target.getBoundingClientRect();
                         setShowHelpArgs({pos: {top: rect.bottom, left: rect.right}});
                     }}>
                    <img src={theme == "dark" ? "/icons8-help-50--dark.png" : "/icons8-help-50--light.png"}
                         title="Help"/>
                </div>
                <div className={styles["icon"]}
                     onClick={() => {
                         if (window.confirm("Are you sure you want to logout?")) {
                             instance.logout();
                         }
                     }}>
                    <img src={theme == "dark" ? "/icons8-logout-50-dark.png" : "/icons8-logout-50-light.png"}
                         title="logout" alt={"logout"}/>
                </div>
            </div>
            {helpModal}
            {moreModal}
            {/*{suggestionModal}*/}
            {loading && <Busy/>}

        </div>
    )
}

export default TopMostNavBar;
