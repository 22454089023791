import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { PublicClientApplication } from '@azure/msal-browser';

//TODO: Move to common location?
const msalConfig = {
    auth: {
        clientId: '4609c939-f740-4f31-8e61-9d5405037924', // Your client id
        authority: 'https://login.microsoftonline.com/63b19fa5-5c31-448f-9fae-b391edb09eab',
        // redirectUri: '/#/home', //config[environment].frontendUrl,
        redirectUri: '/', //config[environment].frontendUrl,
    },
    cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};
console.log( 'create msalInstance object');
const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App msalInstance={msalInstance}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
