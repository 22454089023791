import styles from "./Metrics.module.css"
import Ranking from "../Ranking/Ranking";
import MonthlyUsage from "../MonthlyUsage/MonthlyUsage";
import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import {ThemeContext} from "../../redux/ThemeContext";

function Metrics() {
    const { theme } = useContext(ThemeContext);
    const navigate = useNavigate();
    return (
        <div className={styles['container']}>
            <div className={"title-with-back"}>
                <a onClick={() => navigate(-1)}>
                    <img src={theme == "dark" ? "/icons8-previous-dark-50.png" : "/icons8-previous-light-50.png"}
                         alt="back"/>
                </a>
                <h3>Metrics</h3>
            </div>
            <div className={styles['metrics-container']}>
                <Ranking/>
                <MonthlyUsage/>
            </div>
        </div>
    )
}


export default Metrics;
