import React, {useContext} from 'react';
import styles from './Login.module.css';
import config from "../../config";
import {setRetryLogin, setForceLogin, setErrorMessage} from "../../redux/actions";
import {AppContext} from "../../redux/AppContext";
import { useMsal } from "@azure/msal-react";


function Login() {
    const { state, dispatch } = useContext(AppContext);
    const { retryLogin } = state;
    const { instance } = useMsal();

    function signIn() {
        const loginRequest = {
            scopes: ["User.Read"]
        };
        instance.ssoSilent(loginRequest)
            .then(response => {
                console.log("SSO successful:", response);
                // Handle the response, e.g., save tokens, update UI, etc.
            })
            .catch(error => {
                console.log("SSO failed, falling back to loginPopup");
                instance.loginRedirect(loginRequest)
                    .then(response => {
                        console.log("Login successful:", response);
                        // Handle the response, e.g., save tokens, update UI, etc.
                    })
                    .catch(error => {
                        console.log("Login failed:", error);
                        // Handle the error
                    });
            });    }


    const handleSubmit = () => {
        signIn();
    }


    return (
        <div className={styles["login-container"]}>
                <h1>Welcome to Opus Boost!</h1>
                <p>Hello, Opus Software Team! We're excited to introduce you to our new tool, Opus Boost, designed to supercharge your productivity and inspire innovation across all departments.</p>

                <div className={styles["login-form"]}>
                    <div className={styles["form-group"]}>
                        <button onClick={handleSubmit} className={`${styles["submit-btn"]} button`}>Login</button>
                    </div>
                </div>

                <h2>What is Opus Boost?</h2>
                <p>Opus Boost is a state-of-the-art AI tool, powered by a Large Language Model. It's not just for our brilliant programmers, but for everyone in our diverse team. Whether you're in Marketing, HR, Sales, or Development, Opus Boost is here to assist you.</p>

                <h2>How Can Opus Boost Help You?</h2>
                <h3>For Programmers:</h3>
                <p>Opus Boost can help you generate code, debug issues, and even learn new programming languages. It's like having a virtual pair-programming partner who's available 24/7!</p>

                <h3>For Marketing, Sales, and HR:</h3>
                <p>Opus Boost can help you brainstorm ideas, create compelling documents, and draft persuasive proposals. It's like having an AI-powered copywriter and strategist by your side!</p>

                <h2>Why Use Opus Boost?</h2>
                <p>Opus Boost is more than just a tool - it's your personal AI assistant. It's here to help you work smarter, not harder. With Opus Boost, you can:</p>
                <ul>
                    <li>Save Time: Let Opus Boost handle the routine tasks so you can focus on what matters most.</li>
                    <li>Improve Quality: Opus Boost can help you avoid errors and enhance the quality of your work.</li>
                    <li>Learn and Grow: With Opus Boost, every day is an opportunity to learn something new.</li>
                </ul>

                <h2>Get Started with Opus Boost Today!</h2>
                <p>Ready to boost your productivity? Start exploring Opus Boost now! Remember, this is a tool for all of us. Let's embrace it, explore it, and see how it can transform our work.</p>
                <p>Together, we can achieve more. Together, we can boost our productivity to new heights. Welcome to the future of work. Welcome to Opus Boost!</p>
        </div>

    );
}

export default Login;
