import React, {useState, useEffect, useContext, useRef} from 'react';
import {AppContext} from "../../redux/AppContext";
import styles from "./RecentUpdates.module.css"
import {ThemeContext} from "../../redux/ThemeContext";


const RecentUpdates = () => {
    return (
        <div className="recent-updates">
            <h3>Recent Updates</h3>
            <ul>
                <li>Inclusão dos modelos o1-preview e o1-mini 👉 <a target="_blank" href={"https://openai.com/o1/"}>Introducing OpenAI o1</a></li>
            </ul>
        </div>
    );
};

export default RecentUpdates;
